import { Box } from "@mui/material"
import { ElementType, ForwardedRef, forwardRef } from "react"
import { useSnackbar } from "notistack"
import { LoadingButton } from "@mui/lab"
import { styled } from "@mui/system"
import { StringField } from "./fields/StringField"
import { MoneyField } from "./fields/MoneyField"
import { ChoiceField } from "./fields/ChoiceField"
import { CheckboxField } from "./fields/CheckboxField"
import { DateField } from "./fields/DateField"
import { DateTimeField } from "./fields/DateTimeField"
import { DateIntervalField } from "./fields/DateIntervalField"
import { DateTimeIntervalField } from "./fields/DateTimeIntervalField"
import { AddressField } from "./fields/AddressField"
import { AutocompleteField } from "./fields/AutocompleteField"
import { NestedField } from "./fields/NestedField"
import { FileField } from "./fields/FileField"
import { CollectionTableField } from "./fields/CollectionTableField"
import { CollectionAccordionField } from "./fields/CollectionAccordionField"
import { ReportQuestionPhotoCollectionField } from "./fields/ReportQuestionPhotoCollectionField"
import { Form, useFormRequestContext } from "@w3rone/json-schema-form"
import type {
  FormProps,
  ServerResponse,
  Components,
  FieldMapper,
  FieldSchema,
} from "@w3rone/json-schema-form"
import { CollectionGridField } from "./fields/CollectionGridField"
import { WysiwygField } from "./fields/WysiwygField"
import { SimpleFileField } from "./fields/SimpleFileField"

declare module "react" {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

export const AutoForm = forwardRef(function AutoForm<ResponseDataType>(
  {
    onSubmit,
    onSuccess,
    onError,
    components,
    submitLabel = "Envoyer",
    fieldMapper: fieldMapperProps,
    preventRedirect = false,
    ...props
  }: AutoFormProps<ResponseDataType>,
  ref: ForwardedRef<HTMLFormElement>,
) {
  const { enqueueSnackbar } = useSnackbar()

  const handleSuccess = (data: ServerResponse<ResponseDataType>) => {
    onSuccess?.(data)

    if (data.message) {
      enqueueSnackbar(data.message, { variant: "success" })
    }

    setTimeout(() => {
      if (!data.redirect_url || preventRedirect) {
        return
      }

      window.location.assign(data.redirect_url)
    }, 3000)
  }

  const handleError = (error: unknown) => {
    onError?.(error)

    let message = "Une erreur est survenue"

    if (error instanceof Error && error.message) {
      message = error.message
    }

    enqueueSnackbar(message, { variant: "error" })
  }

  return (
    <Form<ResponseDataType>
      ref={ref}
      components={{ SubmitButtonWrapper, SubmitButton, ...components }}
      onSuccess={handleSuccess}
      onError={handleError}
      onSubmit={onSubmit}
      submitLabel={submitLabel}
      {...props}
      fieldMapper={
        fieldMapperProps
          ? mergeFieldMappers(fieldMapperProps, fieldMapper)
          : fieldMapper
      }
    />
  )
})

type AutoFormProps<ResponseDataType> = Omit<
  FormProps<ResponseDataType>,
  "fieldMapper"
> & {
  fieldMapper?: FormProps<ResponseDataType>["fieldMapper"]
  preventRedirect?: boolean
}

export const SubmitButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: "flex",
  justifyContent: "flex-end",
}))

export const SubmitButton: Components["SubmitButton"] = (props) => {
  const request = useFormRequestContext()

  return (
    <LoadingButton
      loading={request.status === "loading"}
      type="submit"
      variant="contained"
      sx={{
        width: { xs: "100%", md: "auto" },
      }}
    >
      {props.children}
    </LoadingButton>
  )
}

const fieldMapper: FieldMapper = (fieldSchema) => {
  const widgets = new Map<string, ElementType>([
    ["text", StringField],
    ["password", StringField],
    ["email", StringField],
    ["textarea", StringField],
    ["url", StringField],
    ["tel", StringField],
    ["hidden", StringField],
    ["integer", StringField],
    ["number", StringField],
    ["range", StringField],
    ["color", StringField],
    ["money", MoneyField],

    ["choice", ChoiceField],
    ["entity", ChoiceField],
    ["country", ChoiceField],

    ["checkbox", CheckboxField],

    ["date_single_text", DateField],
    ["date_time_single_text", DateTimeField],

    ["date_interval", DateIntervalField],
    ["datetime_interval", DateTimeIntervalField],

    ["address", AddressField],

    ["autocomplete", AutocompleteField],

    ["price", NestedField],

    ["vich_file", FileField],
    ["vich_image", FileField],
    ["file", SimpleFileField],

    ["collection_filter_sort", NullField],
    ["collection_grid", CollectionGridField],
    ["collection_table", CollectionTableField],
    ["collection", CollectionAccordionField],

    ["report_question_photo_collection", ReportQuestionPhotoCollectionField],

    ["wysiwyg", WysiwygField],
  ])

  const component = widgets.get(fieldSchema.options.widget)

  if (component) {
    return component
  }

  if (fieldSchema.type === "object") {
    return NestedField
  }

  return TodoField
}

const TodoField = ({ schema, name }: { schema: FieldSchema; name: string }) => {
  return (
    <div>
      TODO [type:{schema.type},widget:{schema.options.widget}]({name})
    </div>
  )
}

const NullField = () => {
  return null
}

const mergeFieldMappers =
  (...fieldMappers: Array<FieldMapper>): FieldMapper =>
  (fieldSchema) => {
    for (const fieldMapper of fieldMappers) {
      const Component = fieldMapper(fieldSchema)

      if (Component) {
        return Component
      }
    }

    return TodoField
  }
