import { Info } from "@mui/icons-material"
import { Box, FormControl, FormLabel, Input, Tooltip } from "@mui/material"
import type { FieldProps, StringFieldSchema } from "@w3rone/json-schema-form"
import { FormErrors } from "./FormErrors"

export function SimpleFileField({
  name,
  label,
  id,
  description,
  errors,
}: FieldProps<StringFieldSchema>) {
  return (
    <FormControl variant={"standard"} error={errors.length > 0} fullWidth>
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel htmlFor={id}>{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}

      <FormErrors errors={errors} />

      <Input id={id} name={name} type="file" />
    </FormControl>
  )
}
