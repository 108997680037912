import {
  usePlateEditor,
  Plate,
  PlateContent,
  type PlateProps,
  PlateElement,
  PlateLeaf,
  createPlatePlugin,
  useMarkToolbarButtonState,
  useMarkToolbarButton,
  useFormInputProps,
  ParagraphPlugin,
} from "@udecode/plate/react"
import {
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
} from "@udecode/plate-basic-marks/react"
import { deserializeMd, MarkdownPlugin } from "@udecode/plate-markdown"
import {
  useLinkToolbarButton,
  useLinkToolbarButtonState,
  LinkPlugin,
  useFloatingLinkInsertState,
  useFloatingLinkInsert,
  useFloatingLinkEditState,
  LinkFloatingToolbarState,
  useFloatingLinkEdit,
  FloatingLinkUrlInput,
  LinkOpenButton,
} from "@udecode/plate-link/react"
import {
  type UseVirtualFloatingOptions,
  flip,
  offset,
} from "@udecode/plate-floating"
import {
  AddLink,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  LinkOff,
  OpenInNew,
} from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material"
import { createContext, useContext, type PropsWithChildren } from "react"
import * as R from "remeda"

export function WysiwygEditor({
  placeholder,
  onChange,
  initialValue,
  enabledWidgets = DEFAULT_ENABLED_WIDGETS,
}: WysiwygEditorProps) {
  const editor = usePlateEditor({
    value: (editor) => deserializeMd(editor, initialValue),

    plugins: R.filter(
      [
        MarkdownPlugin,
        enabledWidgets.has("bold") ? BoldPlugin : null,
        enabledWidgets.has("italic") ? ItalicPlugin : null,
        enabledWidgets.has("underline") ? UnderlinePlugin : null,
        enabledWidgets.has("link") ? linkPlugin : null,
        ParagraphPlugin,
        ToolbarPlugin,
      ],
      R.isNonNull,
    ),

    override: {
      components: {
        [BoldPlugin.key]: (props) => <PlateLeaf {...props} as={"strong"} />,
        [ItalicPlugin.key]: (props) => <PlateLeaf {...props} as={"em"} />,
        [UnderlinePlugin.key]: (props) => (
          <Box
            {...props}
            component={PlateLeaf}
            as={"span"}
            sx={{ textDecoration: "underline" }}
          />
        ),
        [linkPlugin.key]: (props) => (
          <Link
            {...props}
            as={PlateLeaf}
            sx={{
              textDecoration: "underline",
              color: (theme) => theme.palette.primary.light,
            }}
          />
        ),
        [ParagraphPlugin.key]: (props) => <PlateElement {...props} as={"p"} />,
      },
    },
  })

  return (
    <EnabledWidgetsContext.Provider value={enabledWidgets}>
      <Box
        sx={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: (theme) => theme.palette.grey[300],
          py: 1,
          borderRadius: 4,

          "&:hover, &:focus-within": {
            borderColor: (theme) => theme.palette.grey[800],
          },

          "&:focus-within": {
            outlineWidth: 1,
            outlineStyle: "solid",
            outlineColor: (theme) => theme.palette.grey[800],
          },
        }}
      >
        <Plate editor={editor} onChange={onChange}>
          <Box
            component={PlateContent}
            sx={{ px: 2, outline: "none" }}
            placeholder={placeholder ?? "Entrez une valeur"}
          />
        </Plate>
      </Box>
    </EnabledWidgetsContext.Provider>
  )
}

const DEFAULT_ENABLED_WIDGETS = new Set<string>()

type WysiwygEditorProps = {
  placeholder?: string
  onChange?: PlateProps["onChange"]
  initialValue: string
  enabledWidgets?: Set<string>
}

const EnabledWidgetsContext = createContext<Set<string> | undefined>(undefined)

function useEnabledWidgets() {
  const context = useContext(EnabledWidgetsContext)

  if (!context) {
    throw new Error(
      "`useEnabledWidgets` must be used in a component wrapped in a `EnabledWidgetsContext.Provider`",
    )
  }

  return context
}

const ToolbarPlugin = createPlatePlugin({
  key: "toolbar",
  render: {
    beforeEditable: () => {
      const enabledWidgets = useEnabledWidgets()

      return (
        <Box>
          {enabledWidgets.has("bold") ? (
            <MarkToolbarButton nodeType={BoldPlugin.key} tooltip={"Gras"}>
              <FormatBold />
            </MarkToolbarButton>
          ) : null}

          {enabledWidgets.has("italic") ? (
            <MarkToolbarButton nodeType={ItalicPlugin.key} tooltip={"Italique"}>
              <FormatItalic />
            </MarkToolbarButton>
          ) : null}

          {enabledWidgets.has("underline") ? (
            <MarkToolbarButton
              nodeType={UnderlinePlugin.key}
              tooltip={"Souligné"}
            >
              <FormatUnderlined />
            </MarkToolbarButton>
          ) : null}

          {enabledWidgets.has("link") ? <LinkToolbarButton /> : null}
        </Box>
      )
    },
  },
})

function MarkToolbarButton({
  clear,
  nodeType,
  tooltip,
  ...rest
}: PropsWithChildren<{
  nodeType: string
  clear?: Array<string> | string
  tooltip: string
}>) {
  const state = useMarkToolbarButtonState({ clear, nodeType })
  const { props } = useMarkToolbarButton(state)

  return (
    <Tooltip title={tooltip}>
      <IconButton
        type={"button"}
        {...props}
        {...rest}
        size={"small"}
        sx={{ opacity: state.pressed ? 1 : 0.5 }}
      />
    </Tooltip>
  )
}

function LinkToolbarButton() {
  const state = useLinkToolbarButtonState()
  const { props } = useLinkToolbarButton(state)

  return (
    <Tooltip title={"Lien hypertexte"}>
      <IconButton
        type={"button"}
        {...props}
        size={"small"}
        sx={{ opacity: state.pressed ? 1 : 0.5 }}
      >
        <AddLink />
      </IconButton>
    </Tooltip>
  )
}

const linkPlugin = LinkPlugin.extend({
  render: { afterEditable: () => <LinkFloatingToolbar /> },
})

const floatingOptions: UseVirtualFloatingOptions = {
  middleware: [
    offset(12),
    flip({
      fallbackPlacements: ["bottom-end", "top-start", "top-end"],
      padding: 12,
    }),
  ],
  placement: "bottom-start",
}

function LinkFloatingToolbar({ state }: { state?: LinkFloatingToolbarState }) {
  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  })

  const {
    hidden,
    props: insertProps,
    ref: insertRef,
    textInputProps,
  } = useFloatingLinkInsert(insertState)

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  })

  const {
    editButtonProps,
    props: editProps,
    ref: editRef,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState)

  const inputProps = useFormInputProps({
    preventDefaultOnEnterKeydown: true,
  })

  if (hidden) return null

  const input = (
    <div {...inputProps}>
      <Box sx={{ display: "grid", gap: 2 }}>
        <FloatingLinkUrlInput
          placeholder="https://..."
          data-plate-focus
          asChild
        >
          <TextField size={"small"} label={"URL"} />
        </FloatingLinkUrlInput>

        <TextField size={"small"} label={"Texte"} {...textInputProps} />
      </Box>
    </div>
  )

  const editContent = editState.isEditing ? (
    input
  ) : (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Button type={"button"} {...editButtonProps}>
        Modifier
      </Button>

      <Tooltip title={"Ouvrir dans un nouvel onglet"}>
        <LinkOpenButton asChild>
          <IconButton size="small">
            <OpenInNew />
          </IconButton>
        </LinkOpenButton>
      </Tooltip>

      <Tooltip title={"Supprimer"}>
        <IconButton type="button" size="small" {...unlinkButtonProps}>
          <LinkOff />
        </IconButton>
      </Tooltip>
    </Box>
  )

  return (
    <>
      <Paper
        ref={insertRef}
        elevation={2}
        sx={{
          zIndex: 50,
          width: "auto !important",
          maxWidth: "288px",
          padding: 1,
          borderRadius: 5,
        }}
        {...insertProps}
      >
        {input}
      </Paper>

      <Paper
        ref={editRef}
        elevation={2}
        sx={{
          zIndex: 50,
          width: "auto !important",
          maxWidth: "288px",
          padding: 1,
          borderRadius: 5,
        }}
        {...editProps}
      >
        {editContent}
      </Paper>
    </>
  )
}
