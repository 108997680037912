import type { FieldProps, StringFieldSchema } from "@w3rone/json-schema-form"
import { WysiwygEditor } from "../../WysiwygEditor"
import { Box, FormControl, FormLabel, Tooltip } from "@mui/material"
import { Info } from "@mui/icons-material"
import { FormErrors } from "./FormErrors"
import { useMemo } from "react"

export function WysiwygField({
  label,
  description,
  value,
  name,
  schema,
  onChange,
  errors,
}: FieldProps<StringFieldSchema>) {
  if (value !== undefined && typeof value !== "string") {
    throw new Error(`Bad WyiswygField value: ${value}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options = schema.options as any

  const enabledWidgets = useMemo(
    () => new Set<string>(options.wysiwyg.options),
    [options.wysiwyg.options],
  )

  return (
    <FormControl variant={"standard"} error={errors.length > 0} fullWidth>
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>

          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}

      <FormErrors errors={errors} />

      <WysiwygEditor
        placeholder={schema.options.attr?.placeholder}
        // @ts-expect-error I don't know how to tell TS that the editor has the markdown plugin enabled
        onChange={({ editor }) => onChange?.(editor.api.markdown.serialize())}
        initialValue={value ?? ""}
        enabledWidgets={enabledWidgets}
      />

      <textarea hidden value={value} name={name} readOnly={true} />
    </FormControl>
  )
}
